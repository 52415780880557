<template>
  <button type="button" class="navbar-toggler"
          :aria-expanded="expanded"
          :aria-controls="target"
          @click="handleClick">
      <span></span>
      <span></span>
  </button>
</template>

<script>
export default {
  name: "CloseButton",
  props: {
      target: {
          type: String,
          required: true,
          description: "Close button target element"
      },
      expanded: {
          type: Boolean,
          required: true,
          description: "Whether button is expanded (aria-expanded attribute)"
      }
  },
  methods: {
      handleClick(evt) {
          this.$emit("close"); // Menü kapatma olayını yay
      }
  }
};
</script>

<style scoped>
/* Buton stillerini buraya ekleyebilirsiniz */
</style>
