<template>
    <section class="section section-components pb-4" :class="{ 'rtl': $i18n.locale === 'fa' }"
        style="background-image: url('/img/banner/bg-home-1.png') !important; background-size: cover; background-position: center;">
        <div class="container mb-5">
            <div class="row">
                <div class="col-lg-4 col-sm-4">
                    <div class="card-body card shadow text-center">
                        <div class="tab-pane fade active show" aria-expanded="true" style="">
                            <img class="card-process-box" src="/img/card/p-arrow1.png">
                            <div class="card-number-box bg-gradient-indigo">
                                <img src="/img/icons/siparis_girisi.png" width="40px">
                            </div>
                            <h3 class="mt-5">{{ $t('home.card_title') }}</h3>
                            <p class="description">{{ $t('home.card_desc') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-4">
                    <div class="card-body card shadow text-center">
                        <div class="tab-pane fade active show" aria-expanded="true" style="">
                            <img class="card-process-box" src="/img/card/p-arrow2-1.png">
                            <div class="card-number-box bg-gradient-warning">
                                <img src="/img/icons/uretim_takip.png" width="40px">
                            </div>
                            <h3 class="mt-5">{{ $t('home.card_title2') }}</h3>
                            <p class="description">{{ $t('home.card_desc2') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-4">
                    <div class="card-body card shadow text-center">
                        <div class="tab-pane fade active show" aria-expanded="true" style="">
                            <div class="card-number-box bg-gradient-success">
                                <img src="/img/icons/cari_yonetimi.png" width="40px">
                            </div>
                            <h3 class="mt-5">{{ $t('home.card_title3') }}</h3>
                            <p class="description">{{ $t('home.card_desc3') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>
