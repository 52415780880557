<template>
    <section class="section-hero section-shaped my-0" :class="{ 'rtl': $i18n.locale === 'fa' }"
        style="background-image: url('/img/banner/goldenmes-banner.png') !important; background-size: cover; background-position: center;">
        <div class="container shape-container d-flex align-items-center">
            <div class="col px-0">
                <div class="row align-items-center justify-content-around stars-and-coded">
                    <div class="col-sm-6">
                        <h1 class="text-white font-black">{{ $t('home.banner_title') }}</h1>
                        <p class="lead text-white mt-4 mb-0">{{ $t('home.banner_desc') }}</p>
                        <div class="btn-wrapper mt-4">
                            <base-button tag="a" href="https://www.gunili.com/about" class="mb-3 mb-sm-0" type="info">
                                {{ $t('home.banner_button') }}
                            </base-button>
                            <base-button tag="a" href="mailto:info@gunili.com" class="mb-3 mb-sm-0" type="white">
                                {{ $t('home.banner_button2') }}
                            </base-button>
                        </div>
                    </div>
                    <div class="col-sm-6 mt-sm-0 pt-4 p-0 text-right">
                        <img src="/img/banner/banner-pc.png" width="100%" class="img-fluid floating">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>
