<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <router-view></router-view> <!-- Bu kısımda içerik yüklenir -->
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* Stil ayarları burada */
</style>
