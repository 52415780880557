import { createRouter, createWebHistory } from 'vue-router';
import AppHeader from './layout/AppHeader.vue';
import PageHeader from './layout/PageHeader.vue';
import AppFooter from './layout/AppFooter.vue';
import Components from './views/Components.vue';
import Contact from './views/Contact.vue';

// Rota tanımları
const routes = [
  {
    path: '/',
    name: 'components',
    components: {
      header: AppHeader,
      default: Components,
      footer: AppFooter
    }
  },
  {
    path: '/contact',
    name: 'contact',
    components: {
      header: AppHeader,
      pageheader: PageHeader,
      default: Contact,
      footer: AppFooter
    }
  }
];

// Router oluşturma
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
