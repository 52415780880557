import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Argon from './plugins/argon-kit';
import { createI18n } from 'vue-i18n';
import tr from './locales/tr.json';
import en from './locales/en.json';
import fa from './locales/fa.json'; // Farsça dil dosyasını ekleyin

// LocalStorage'dan dili al, varsayılan olarak 'tr' kullan
const savedLanguage = localStorage.getItem('user-language') || 'tr';

// Vue I18n yapılandırması
const i18n = createI18n({
  locale: savedLanguage, // Varsayılan dil
  messages: {
    tr,
    en,
    fa // Farsça dilini burada dahil edin
  }
});

const app = createApp(App);

// Argon plugin'ini kullanma
app.use(Argon);
app.use(router);
app.use(i18n); // I18n'i ekleme

// Uygulamayı mount etme
app.mount('#app');

// Dil değişikliğini kaydetmek için örnek bir metod (isteğe bağlı)
app.config.globalProperties.$setLanguage = (lang) => {
  localStorage.setItem('user-language', lang); // Dil kaydet
  i18n.global.locale = lang; // I18n dilini değiştir
};
