<template>
    <div>
        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3 text-white">{{ t('contact.title') }}</h1>
                                <h6 class="text-white">{{ t('contact.subtitle') }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section :class="{ 'rtl': $i18n.locale === 'fa' }" class="section section-lg"
            style="background-image: url('/img/banner/gunuli-linkedin-kapak.png') !important; background-size: cover; background-position: center;">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-2">
                        <card gradient="secondary" shadow body-classes="p-lg-5">
                            <h4 class="mb-1">{{ t('contact.subtitle3') }}</h4>
                            <p class="mt-0">{{ t('contact.desc2') }}</p>
                            <form @submit.prevent="sendMail">
                                <base-input v-model="name" alternative placeholder="Adınız"
                                    addon-left-icon="ni ni-user-run"></base-input>
                                <base-input v-model="email" alternative placeholder="E-posta adresi"
                                    addon-left-icon="ni ni-email-83"></base-input>
                                <base-input class="mb-4">
                                    <textarea class="form-control form-control-alternative" v-model="message" rows="4"
                                        cols="80" placeholder="Bir mesaj yazın..."></textarea>
                                </base-input>
                                <base-button type="submit" class="btn btn-block btn-primary btn-lg" round block
                                    size="lg">{{ t('contact.button') }}</base-button>
                            </form>
                            <div v-if="errorMessage" class="text-danger">{{ errorMessage }}</div>
                        </card>
                    </div>
                    <div class="col-md-6 order-md-1">
                        <div class="pr-md-5">
                            <h3>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ t('contact.subtitle2') }}</font>
                                </font>
                            </h3>
                            <p>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ t('contact.desc') }}
                                    </font>
                                </font>
                            </p>
                            <ul class="list-unstyled mt-5">
                                <li class="py-2">
                                    <div class="d-flex align-items-center"><span
                                            class="badge badge-success badge-circle2"><i
                                                class="fa fa-map-marker"></i></span>
                                        <h6 class="mb-0">
                                            <font style="vertical-align: inherit;">
                                                <font style="vertical-align: inherit;">{{ t('contact.address') }}:
                                                    <a href="https://www.google.com/maps/place/G%C3%9CN%C4%B0L%C4%B0+YAZILIM+VE+M%C3%9CHEND%C4%B0SL%C4%B0K+A.%C5%9E./@40.923167,29.314075,19z/data=!4m6!3m5!1s0x14cadb29f896271d:0x7bb90eafc50730ec!8m2!3d40.9231665!4d29.3140746!16s%2Fg%2F11gjwvg98d?hl=tr&amp;entry=ttu&amp;g_ep=EgoyMDI0MTAwNy4xIKXMDSoASAFQAw%3D%3D"
                                                        target="_blank" rel="noopener">Sanayi Mah.
                                                        Teknopark Bulvarı No:
                                                        1/1C, D:1503, 34906 Pendik / Istanbul</a>
                                                </font>
                                            </font>
                                        </h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center"><span
                                            class="badge badge-success badge-circle"><i
                                                class="fa fa-envelope"></i></span>
                                        <h6 class="mb-0">
                                            <font style="vertical-align: inherit;">
                                                <font style="vertical-align: inherit;">{{ t('contact.phone') }}:
                                                    <a href="tel:+905079917439" target="_blank" rel="noopener">0507 991
                                                        74
                                                        39</a>
                                                </font>
                                            </font>
                                        </h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center"><span
                                            class="badge badge-success badge-circle"><i class="fa fa-phone"></i></span>
                                        <h6 class="mb-0">
                                            <font style="vertical-align: inherit;">
                                                <font style="vertical-align: inherit;">{{ t('contact.email') }}:
                                                    <a href="mailto:info@gunili.com" target="_blank"
                                                        rel="noopener">info@gunili.com</a>
                                                </font>
                                            </font>
                                        </h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <a target="_blank" rel="noopener"
                                        href="https://www.linkedin.com/company/gunili/?originalSubdomain=tr"
                                        class="btn btn-neutral btn-icon-only btn-instagram btn-round btn-lg"
                                        data-toggle="tooltip" data-original-title="Linkedin"><i
                                            class="fa fa-linkedin"></i></a>
                                    <a target="_blank" rel="noopener"
                                        href="https://www.facebook.com/guniliyazilim/?locale=tr_TR"
                                        class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg"
                                        data-toggle="tooltip" data-original-title="Facebook"><i
                                            class="fa fa-facebook-square"></i></a>
                                    <a target="_blank" rel="noopener"
                                        href="https://www.instagram.com/guniliyazilim/?igsh=MWpoenJsbXo1eWMxcw%3D%3D"
                                        class="btn btn-neutral btn-icon-only btn-dribbble btn-lg btn-round"
                                        data-toggle="tooltip" data-original-title="Instagramn"><i
                                            class="fa fa-instagram"></i></a>
                                    <a target="_blank" rel="noopener"
                                        href="https://www.youtube.com/channel/UCE3vBVCLhCCFNRJN4GmaY3Q"
                                        class="btn btn-neutral btn-icon-only btn-youtube btn-round btn-lg"
                                        data-toggle="tooltip" data-original-title="Youtube"><i
                                            class="fa fa-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-lg p-0">
            <div class="container-fluid px-0">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1507.3307575217825!2d29.314075000000003!3d40.923167!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cadb29f896271d%3A0x7bb90eafc50730ec!2zR8OcTsSwTMSwIFlBWklMSU0gVkUgTcOcSEVORMSwU0zEsEsgQS7Fni4!5e0!3m2!1str!2sus!4v1729516168347!5m2!1str!2sus"
                    width="100%" height="650" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    setup() {
        const { t } = useI18n();
        const name = ref('');
        const email = ref('');
        const message = ref('');
        const errorMessage = ref('');

        const sendMail = async () => {
            const formData = {
                name: name.value,
                email: email.value,
                message: message.value,
            };

            try {
                const response = await axios.post('https://your-api.com/send-mail', formData);

                console.log('E-posta başarıyla gönderildi:', response.data);
                resetForm();
                errorMessage.value = ''; // Hata mesajını temizle
            } catch (error) {
                console.error('E-posta gönderim hatası:', error);
                errorMessage.value = 'E-posta gönderirken bir hata oluştu. Lütfen tekrar deneyin.'; // Hata mesajı ayarla
            }
        };

        const resetForm = () => {
            name.value = '';
            email.value = '';
            message.value = '';
        };

        return { t, name, email, message, sendMail, errorMessage };
    },
});
</script>
