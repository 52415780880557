<template>
    <section class="section section-components pt-4 pb-0" style="background-image: url('/img/banner/pc-home-bg.png') !important; background-size: cover; background-position: center;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-sm-12 text-center">
                    <img src="/img/prices/pc-tema.png" width="100%">
                </div>
            </div>
        </div>
    </section>
</template>
