<template>
  <nav class="navbar"
    :class="[{ 'navbar-expand-lg': expand }, { [`navbar-${effect}`]: effect }, { 'navbar-transparent': transparent }, { [`bg-${type}`]: type }, { 'rounded': round }]">
    <div class="container" :class="{ 'rtl': $i18n.locale === 'fa' }">
      <slot name="container-pre"></slot>
      <slot name="brand">
        <a class="navbar-brand" href="#" @click.prevent="onTitleClick">
          <h4 class="font-bold text-white mb-0">Golden<b class="text-primary">MES</b></h4>
        </a>
      </slot>
      <navbar-toggle-button :toggled="toggled" :target="contentId" @click.native.stop="toggleMenu">
      </navbar-toggle-button>



      <slot name="container-after"></slot>

      <div class="collapse navbar-collapse" :class="{ show: toggled }" :id="contentId" v-click-outside="closeMenu">
        <div class="row d-lg-none">
          <div class="col-6 d-flex align-items-center">
            <a href="">
              <h4 class="font-bold">GoldenMES</h4>
            </a>
          </div>
          <div class="col-6 collapse-close d-flex justify-content-end align-items-center" v-if="toggled"
            style="display: flex;">
            <button class="close-button" @click.native="closeMenu" aria-label="Close menu" style="margin-right: 5px;">
              &times;
            </button>
          </div>
        </div>
        <slot :close-menu="closeMenu"></slot>
      </div>
    </div>
  </nav>
</template>


<script>
import { FadeTransition } from "vue2-transitions";
import NavbarToggleButton from "./NavbarToggleButton";
import clickOutside from "@/directives/v-click-outside"; // Direktifi içe aktar

export default {
  name: "base-nav",
  components: {
    FadeTransition,
    NavbarToggleButton
  },
  directives: {
    clickOutside // Direktifi tanımla
  },
  props: {
    type: {
      type: String,
      default: "primary"
    },
    title: {
      type: String,
      default: ""
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString()
    },
    effect: {
      type: String,
      default: "dark"
    },
    round: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    onTitleClick(evt) {
      this.$emit("title-click", evt);
    },
    closeMenu() {
      this.toggled = false; // Menü kapatılır
      console.log("Menü kapatıldı");
    },
    toggleMenu() {
      this.toggled = !this.toggled; // Menü açma/kapama
    }
  }
};
</script>
