<script>
export default {
    name: 'app-footer',
    data() {
        return {
            year: new Date().getFullYear()
        }
    }
};
</script>
<template>
    <footer class="footer has-cards" style="background-image: url('/img/banner/bg-footer.png') !important; 
           background-size: cover; 
           background-position: center center; 
           background-repeat: no-repeat;">

        <div class="container text">
            <div class="row row-grid align-items-center mt-5">
                <div class="col-md-12 text-center mt-3 mb-5">
                    <div class="copyright text-white">
                        <h2 class="text-white font-bold">Golden<b class="text-primary">MES</b></h2>
                    </div>
                </div>
                <div class="col-lg-4 text-center border-right mt-5">
                    <div class="text-white">
                        <i class="fa fa-map-marker fa-2x" style="color: #04cef0;"></i>
                    </div>
                    <h6 class="text-white font-weight-light mt-4"><a href="https://www.google.com/maps/place/G%C3%9CN%C4%B0L%C4%B0+YAZILIM+VE+M%C3%9CHEND%C4%B0SL%C4%B0K+A.%C5%9E./@40.923167,29.314075,19z/data=!4m6!3m5!1s0x14cadb29f896271d:0x7bb90eafc50730ec!8m2!3d40.9231665!4d29.3140746!16s%2Fg%2F11gjwvg98d?hl=tr&entry=ttu&g_ep=EgoyMDI0MTAwNy4xIKXMDSoASAFQAw%3D%3D" target="_blank"
                        rel="noopener" class="text-white hover:text-orange">Sanayi Mah. Teknopark Bulvarı No: 1/1C, D:1503, 34906
                        Pendik / Istanbul</a></h6>
                    <h6 class="mb-0 font-weight-light" style="color: #04cef0;"><b>{{ $t('home.footer_address') }}</b></h6>
                </div>
                <div class="col-lg-4 text-center border-right mt-5">
                    <div class="text-white">
                        <i class="fa fa-envelope fa-2x" style="color: #04cef0;"></i>
                    </div>
                    <h6 class="text-white font-weight-light mt-4"><a href="mailto:info@gunili.com" target="_blank"
                            rel="noopener" class="text-white hover:text-orange">info@gunili.com</a></h6>
                    <h6 class="mb-0 font-weight-light" style="color: #04cef0;"><b>{{ $t('home.footer_mail') }}</b></h6>
                </div>
                <div class="col-lg-4 text-center mt-5">
                    <div class="text-white">
                        <i class="fa fa-phone fa-2x" style="color: #04cef0;"></i>
                    </div>
                    <h6 class="text-white font-weight-light mt-4"><a href="tel:+905079917439" target="_blank"
                            rel="noopener" class="text-white hover:text-orange">0507 991 74 39</a></h6>
                    <h6 class="mb-0 font-weight-light" style="color: #04cef0;"><b>{{ $t('home.footer_telephone') }}</b></h6>
                </div>
                <div class="col-md-12 text-center">
                    <ul class="nav nav-footer justify-content-center text-white">
                        <li class="nav-item"><a href="https://www.gunili.com/" target="_blank" rel="noopener"
                                class="nav-link">{{ $t('home.footer_menu') }}</a></li>
                        <li class="nav-item"><a href="https://www.gunili.com/" target="_blank" rel="noopener"
                                class="nav-link">{{ $t('home.footer_menu2') }}</a></li>
                        <li class="nav-item"><a href="https://www.gunili.com/" target="_blank" rel="noopener"
                                class="nav-link">{{ $t('home.footer_menu3') }}</a></li>
                        <li class="nav-item"><a href="https://www.gunili.com/" target="_blank" rel="noopener"
                                class="nav-link">{{ $t('home.footer_menu4') }}</a></li>
                    </ul>
                </div>
                <div class="col-md-12 text-center mt-3">
                    <div class="copyright text-white">
                        &copy; {{ year }}
                        <a href="https://www.gunili.com/" target="_blank" rel="noopener">Günili Yazılım ve Mühendislik
                            A.Ş. </a> & <a href="https://www.gunili.com/" target="_blank" rel="noopener">GoldenMES</a>
                    </div>
                </div>
                <div class="col-lg-12 text-lg-center btn-wrapper text-center mt-3 mb-4">
                    <a target="_blank" rel="noopener"
                        href="https://www.linkedin.com/company/gunili/?originalSubdomain=tr"
                        class="btn btn-neutral btn-icon-only btn-instagram btn-round btn-lg" data-toggle="tooltip"
                        data-original-title="Linkedin">
                        <i class="fa fa-linkedin"></i>
                    </a>
                    <a target="_blank" rel="noopener" href="https://www.facebook.com/guniliyazilim/?locale=tr_TR"
                        class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"
                        data-original-title="Facebook">
                        <i class="fa fa-facebook-square"></i>
                    </a>
                    <a target="_blank" rel="noopener"
                        href="https://www.instagram.com/guniliyazilim/?igsh=MWpoenJsbXo1eWMxcw%3D%3D"
                        class="btn btn-neutral btn-icon-only btn-dribbble btn-lg btn-round" data-toggle="tooltip"
                        data-original-title="Instagramn">
                        <i class="fa fa-instagram"></i>
                    </a>
                    <a target="_blank" rel="noopener" href="https://www.youtube.com/channel/UCE3vBVCLhCCFNRJN4GmaY3Q"
                        class="btn btn-neutral btn-icon-only btn-youtube btn-round btn-lg" data-toggle="tooltip"
                        data-original-title="Youtube">
                        <i class="fa fa-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>
