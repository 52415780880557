<template>
    <section :class="{ 'rtl': $i18n.locale === 'fa' }" class="section section-components mb-5"
        style="background-image: url('/img/banner/bg-up-home7.png') !important; background-size: cover; background-position: center;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-sm-12 text-center">
                    <small class="text-uppercase pt-5 text-muted font-weight-bold">{{ $t('home.moduls_subtitle')
                        }}</small>
                    <h3 class="font-black display-2">{{ $t('home.moduls_title') }}</h3>
                    <p class="description mx-5">{{ $t('home.moduls_subdesc') }}</p>
                </div>

                <!-- Kartlar -->
                <div class="col-lg-3 col-sm-6">
                    <div class="card-body card shadow2 card-moduls mt-5"
                        :class="{ 'card-moduls-left': $i18n.locale === 'fa' }">
                        <!-- Kart 1 -->
                        <div class="tab-pane fade active show" aria-expanded="true">
                            <div class="row">
                                <div class="col-lg-2 col-sm-6 p-0">
                                    <div class="parent">
                                        <div
                                            class="child icon icon-shape bg-gradient-primary rounded-circle text-white">
                                            <i class="ni ni-ui-04"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-sm-6">
                                    <h5 class="text-primary">{{ $t('home.moduls_subheading') }}</h5>
                                    <p class="description">{{ $t('home.moduls_desc') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body card shadow2 mt-5">
                        <!-- Kart 2 -->
                        <div class="tab-pane fade active show" aria-expanded="true">
                            <div class="row">
                                <div class="col-lg-2 col-sm-6 p-0">
                                    <div class="parent">
                                        <div
                                            class="child icon icon-shape bg-gradient-warning rounded-circle text-white">
                                            <i class="ni ni-basket"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-sm-6">
                                    <h5 class="text-warning">{{ $t('home.moduls_subheading2') }}</h5>
                                    <p class="description">{{ $t('home.moduls_desc2') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body card shadow2 card-moduls mt-5"
                        :class="{ 'card-moduls-left': $i18n.locale === 'fa' }">
                        <!-- Kart 3 -->
                        <div class="tab-pane fade active show" aria-expanded="true">
                            <div class="row">
                                <div class="col-lg-2 col-sm-6 p-0">
                                    <div class="parent">
                                        <div
                                            class="child icon icon-shape bg-gradient-success rounded-circle text-white">
                                            <i class="fa fa-spinner"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-sm-6">
                                    <h5 class="text-success">{{ $t('home.moduls_subheading3') }}</h5>
                                    <p class="description">{{ $t('home.moduls_desc3') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 mt-5 mb-0 pb-0 text-center">
                    <img src="/img/moduls/image2-home7.png" width="100%" class="img-fluid floating">
                </div>

                <!-- Sağdaki kartlar -->
                <div class="col-lg-3 col-sm-6">
                    <div class="card-body card shadow2 card-moduls-right mt-5"
                        :class="{ 'card-moduls-right': $i18n.locale === 'fa' }">
                        <!-- Kart 4 -->
                        <div class="tab-pane fade active show" aria-expanded="true">
                            <div class="row">
                                <div class="col-lg-2 col-sm-6 p-0">
                                    <div class="parent">
                                        <div class="child icon icon-shape bg-gradient-indigo rounded-circle text-white">
                                            <i class="ni ni-money-coins"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-sm-6">
                                    <h5 class="text-indigo">{{ $t('home.moduls_subheading4') }}</h5>
                                    <p class="description">{{ $t('home.moduls_desc4') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body card shadow2 mt-5">
                        <!-- Kart 5 -->
                        <div class="tab-pane fade active show" aria-expanded="true">
                            <div class="row">
                                <div class="col-lg-2 col-sm-6 p-0">
                                    <div class="parent">
                                        <div class="child icon icon-shape bg-gradient-danger rounded-circle text-white">
                                            <i class="ni ni-bullet-list-67"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-sm-6">
                                    <h5 class="text-danger">{{ $t('home.moduls_subheading5') }}</h5>
                                    <p class="description">{{ $t('home.moduls_desc5') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body card shadow2 card-moduls-right mt-5"
                        :class="{ 'card-moduls-right': $i18n.locale === 'fa' }">
                        <!-- Kart 6 -->
                        <div class="tab-pane fade active show" aria-expanded="true">
                            <div class="row">
                                <div class="col-lg-2 col-sm-6 p-0">
                                    <div class="parent">
                                        <div
                                            class="child icon icon-shape bg-gradient-gray-dark rounded-circle text-white">
                                            <i class="ni ni-archive-2"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-sm-6">
                                    <h5 class="text-pink">{{ $t('home.moduls_subheading6') }}</h5>
                                    <p class="description">{{ $t('home.moduls_desc6') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center align-items-center card-moduls-left card-moduls-center mt-5">
                    <div class="col-lg-10 col-sm-6">
                        <div
                            class="card-body card shadow2 d-flex flex-column justify-content-center align-items-center">
                            <div class="tab-pane fade active show" aria-expanded="true">
                                <div class="row">
                                    <div class="col-lg-2 col-sm-6 p-0">
                                        <div class="parent">
                                            <div class="child icon icon-shape bg-pink rounded-circle text-white"><i
                                                    class="ni ni-delivery-fast"></i></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-10 col-sm-6">
                                        <h5 class="text-indigo">{{ $t('home.moduls_subheading7') }}</h5>
                                        <p class="description">{{ $t('home.moduls_desc7') }}.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style>
/* .card-moduls-right ve .card-moduls-left sadece Farsça dilinde uygulansın */
.rtl .card-moduls-right {
    margin: 0px -70px 0px 70px;
}

.rtl .card-moduls-left {
    margin: 0px 70px 0px -70px;
}
</style>
