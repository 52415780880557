<template>
    <section class="section section-components pt-4 pb-0" :class="{ 'rtl': $i18n.locale === 'fa' }">
        <div class="price has-cards2">
            <div class="container container-lg"></div>
        </div>
        <div class="container mb-5 mt-5">
            <div class="row">
                <div class="col-lg-12 col-sm-12 text-center mt-5">
                    <small class="text-uppercase font-weight-bold text-white">{{ $t('home.prices_subtitle') }}</small>
                    <h3 class="font-black display-2 text-white">{{ $t('home.prices_title') }}</h3>
                    <p class="description text-white px-5">{{ $t('home.prices_desc') }}</p>
                </div>
                <div class="col-lg-4 col-sm-4 mb-5">
                    <div class="card-lift--hover card-body card-prices shadow2 mt-5 text-center">
                        <div aria-expanded="true" class="tab-pane fade active show">
                            <span class="title-table text-white">Basic</span>
                            <div class="parent mt-5 mb-2">
                                <img src="/img/prices/prices-1.png" width="150px">
                            </div>
                            <div class="d-flex justify-content-center align-items-center">
                                <h4 class="display-2 mb-0">$500 </h4>
                                <span class="badge badge-pill badge-info text-uppercase ml-1">{{ $t('home.prices_subdesc') }}</span>
                            </div>
                            <small class="text-uppercase pt-5 text-muted font-weight-bold">{{ $t('home.prices_subheading') }}</small>
                            <hr>
                            </hr>
                            <p>{{ $t('home.prices_text') }}</p>
                            <p>{{ $t('home.prices_text2') }}</p>
                            <p>{{ $t('home.prices_text4') }}</p>
                            <p>{{ $t('home.prices_text5') }}</p>
                            <p>{{ $t('home.prices_text7') }}</p>
                            <a type="mail" href="mailto:info@gunili.com" i=""
                                class="card-number-button btn mb-3 mt-0 mb-sm-0 btn-info">
                                {{ $t('home.prices_button') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-4 mb-5">
                    <div class="card-lift--hover card-body card-prices shadow2 mt-5 bg-gradient-warning text-center">
                        <div aria-expanded="true" class="tab-pane fade active show">
                            <span class="title-table bg-white">Standard</span>
                            <div class="parent mt-5 mb-2">
                                <img src="/img/prices/prices-2.png" width="150px">
                            </div>
                            <div class="d-flex justify-content-center align-items-center">
                                <h4 class="display-2 text-white mb-0">$1.000</h4>
                                <span class="badge badge-pill badge-warning text-uppercasee ml-1">{{ $t('home.prices_subdesc') }}</span>
                            </div>
                            <small class="text-uppercase pt-5 text-white font-weight-bold">{{ $t('home.prices_subheading') }}</small>
                            <hr>
                            </hr>
                            <p class="text-white">{{ $t('home.prices_text') }}</p>
                            <p class="text-white">{{ $t('home.prices_text2') }}</p>
                            <p class="text-white">{{ $t('home.prices_text3') }}</p>
                            <p class="text-white">{{ $t('home.prices_text4') }}</p>
                            <p class="text-white">{{ $t('home.prices_text5') }}</p>
                            <p class="text-white">{{ $t('home.prices_text7') }}</p>
                            <a type="mail" href="mailto:info@gunili.com" i=""
                                class="card-number-button2 btn mb-3 mt-0 mb-sm-0 btn-info">
                                {{ $t('home.prices_button') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-4">
                    <div class="card-lift--hover card-body card-prices shadow2 mt-5 text-center">
                        <div aria-expanded="true" class="tab-pane fade active show">
                            <span class="title-table text-white">Enterprise</span>
                            <div class="parent mt-5 mb-2">
                                <img src="/img/prices/prices-3.png" width="150px">
                            </div>
                            <div class="d-flex justify-content-center align-items-center">
                                <h4 class="display-2 mb-0">$1.500</h4>
                                <span class="badge badge-pill badge-info text-uppercase ml-1">{{ $t('home.prices_subdesc') }}</span>
                            </div>
                            <small class="text-uppercase pt-5 text-muted font-weight-bold">{{ $t('home.prices_subheading') }}</small>
                            <hr />
                            <p>{{ $t('home.prices_text') }}</p>
                            <p>{{ $t('home.prices_text2') }}</p>
                            <p>{{ $t('home.prices_text3') }}</p>
                            <p>{{ $t('home.prices_text4') }}</p>
                            <p>{{ $t('home.prices_text5') }}</p>
                            <p>{{ $t('home.prices_text6') }}</p>
                            <p>{{ $t('home.prices_text7') }}</p>
                            <a type="mail" href="mailto:info@gunili.com" i=""
                                class="card-number-button3 btn mb-3 mt-0 mb-sm-0 btn-info">
                                {{ $t('home.prices_button') }}
                            </a>
                        </div>
                    </div>
                </div>

                

                

                <div class="col-lg-12 col-md-12">
                    
                </div>

            </div>
        </div>
    </section>
</template>
