export default {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      // Eğer tıklanan hedef, el'den farklıysa ve el'e ait değilse
      if (!(el == event.target || el.contains(event.target))) {
        binding.value(event); // Kapatma işlevini çağır
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.removeEventListener("click", el.clickOutsideEvent);
  }
};
