<template>
    <div>
        <hero></hero>
        <card></card>
        <about></about>
        <moduls></moduls>
        <features></features>
        <prices></prices>
        <pc></pc>
    </div>
</template>
<script>
import Hero from "./components/Hero";
import Card from "./components/Card";
import About from "./components/About";
import Moduls from "./components/Moduls";
import Features from "./components/Features";
import Prices from "./components/Prices";
import pc from "./components/Pc";

export default {
  name: "components",
  components: {
    Hero,
    Card,
    Moduls,
    Features,
    About,
    Prices,
    pc,
  }
};
</script>
