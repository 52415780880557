<template>
    <section class="section" :class="{ 'rtl': $i18n.locale === 'fa' }" style="background-image: url('/img/banner/bg-about.png') !important; 
           background-size: cover; 
           background-position: center center; 
           background-repeat: no-repeat;">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <img src="/img/about/about-home-2.png" width="100%" class="img-fluid floating">
                </div>
                <div class="col-lg-6 col-sm-6 pt-5 mt-md-0">
                    <small class="text-uppercase text-muted font-weight-bold pt-5">{{ $t('home.about_subtitle') }}</small>
                    <h3 class="font-black display-2">{{ $t('home.about_title') }}</h3>
                    <p class="mt-4">{{ $t('home.about_desc') }}</p>
                    <p>{{ $t('home.about_desc2') }}</p>
                    <a type="GoldenMes" class="btn mb-3 mb-sm-0 btn-info" href="https://www.gunili.com/GoldenMes">
                        {{ $t('home.about_button') }}
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";

export default {
    components: {
        BaseNav,
        CloseButton
    },
    data() {
        return {
            checkboxes: {
                unchecked: false,
                checked: true,
                uncheckedDisabled: false,
                checkedDisabled: true
            },
            radio: {
                radio1: "radio1",
                radio2: "radio3"
            },
            switches: {
                off: false,
                on: true
            },
            sliders: {
                slider1: 0,
                slider2: [150, 400]
            }
        };
    }
};
</script>

<style></style>
