<template>
    <section :class="{ 'rtl': $i18n.locale === 'fa' }" class="section section-components mb-5 mt-5"
        style="background-size: cover;background-image: linear-gradient(361deg, #ffffff, #f0f6ff);">
        <div class="container">
            <div class="row">

                <div class="col-lg-4 col-sm-6 mt-5">
                    <small class="text-uppercase pt-5 text-muted font-weight-bold">{{ $t('home.features_subtitle')
                        }}</small>
                    <h3 class="font-black display-2">{{ $t('home.features_title') }}</h3>
                    <p class="description">{{ $t('home.features_desc') }}</p>
                    <a type="" class="btn btn-info mb-3 mb-sm-0" href="https://www.gunili.com/about">{{ $t('home.features_button') }}</a>
                </div>

                <div class="col-lg-4 col-sm-6 mt-5">
                    <div class="card-body p-0">
                        <div class="d-flex">
                            <div>
                                <div class="icon icon-shape mb-4 icon-shape-warning rounded-circle"><i
                                        class="ni ni-check-bold"></i></div>
                            </div>
                            <div class="px-4">
                                <h5 class="title text-warning">{{ $t('home.features_subheading') }}</h5>
                                <p>{{ $t('home.features_text') }}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="card-body p-0">
                        <div class="d-flex">
                            <div>
                                <div class="icon icon-shape mb-4 icon-shape-success rounded-circle"><i
                                        class="ni ni-check-bold"></i></div>
                            </div>
                            <div class="px-4">
                                <h5 class="title text-success">{{ $t('home.features_subheading2') }}</h5>
                                <p>{{ $t('home.features_text2') }}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="card-body p-0">
                        <div class="d-flex">
                            <div>
                                <div class="icon icon-shape mb-4 icon-shape-primary rounded-circle"><i
                                        class="ni ni-check-bold"></i></div>
                            </div>
                            <div class="px-4">
                                <h5 class="title text-primary">{{ $t('home.features_subheading3') }}</h5>
                                <p>{{ $t('home.features_text3') }}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-4 col-sm-6 mt-5">
                    <div class="card-body p-0">
                        <div class="d-flex">
                            <div>
                                <div class="icon icon-shape mb-4 icon-shape-default rounded-circle"><i
                                        class="ni ni-check-bold"></i></div>
                            </div>
                            <div class="px-4">
                                <h5 class="title text-default">{{ $t('home.features_subheading4') }}</h5>
                                <p>{{ $t('home.features_text4') }}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="card-body p-0">
                        <div class="d-flex">
                            <div>
                                <div class="icon icon-shape mb-4 icon-shape-danger rounded-circle"><i
                                        class="ni ni-check-bold"></i></div>
                            </div>
                            <div class="px-4">
                                <h5 class="title text-danger">{{ $t('home.features_subheading5') }}</h5>
                                <p>{{ $t('home.features_text5') }}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="card-body p-0">
                        <div class="d-flex">
                            <div>
                                <div class="icon icon-shape mb-4 icon-shape-info rounded-circle"><i
                                        class="ni ni-check-bold"></i></div>
                            </div>
                            <div class="px-4">
                                <h5 class="title text-info">{{ $t('home.features_subheading6') }}</h5>
                                <p>{{ $t('home.features_text6') }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>
